#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255,255,255,.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.logo {
  float: left;
  color: white;

}

span.mail {
  font-family: Consolas, monospace; }
span.mail::after {
  content: attr(data-dom); }
span.mail::before {
  content: attr(data-addr) "@"; }